.contactbackground{
    background-color: #0E0E0E;
    border-radius: 10px;
}

.inputfields{
    border-radius: 8px;
    background-color: #181818; 
    width: 49%;
    font-size: 20px;
    padding: 10px;
    box-shadow: none !important;
    outline: none !important;
     color: white;
    border: 1px solid #2B2B2B;
}
.inputfields3{
    border-radius: 8px;
    background-color: #181818; 
    width: 49%;
    font-size: 20px;
    padding: 10px;
    color: white;
    border: 1px solid #2B2B2B;
}
.inputfields2{
    border-radius: 8px;
    background-color: #181818;
    border: 1px solid #2B2B2B;
    width: 100%;
    font-size: 20px;
    padding: 10px;
}


.getstartedbutton{
    background-color: white;
    padding: 10px 25px;
    color: black;
    font-weight: 600;
    border: none;
    border-radius: 8px;
}