@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&family=Playfair+Display&display=swap');

.App {
  text-align: center;
}

body {
  font-family: 'Manrope', sans-serif;
  font-family: 'Playfair Display', serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.a{
  text-decoration: none;
}
.rounded{
  color: white;
  font-size: 17px;
}

.navbariii {
  background-color: #282C2F;
  color: white !important;
}

.headerlogo {
  height: 100px;
}

.logofooter {
  height: 4rem !important;
}

.btn-colorforgroup {
  background-color: #282C2F;
  border-color: #282C2F;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 6rem !important;
  height: 6rem !important;
}

.paragraphforabout{
  font-size: 15px;
}

.borderline{
  width: 70px;
  height: 2px;
  background-color: #99999a;
  border-radius: 3px;
}

.readmorebtn{
  background-color: #282C2F;
  text-decoration: underline;
  color: white;
  font-weight: 600;
  width: fit-content;
  padding: 7px 10px;
  border-radius: 5px;
}

.aboutusbg{
  background-color: #282C2F;
  color: white;
}

.readless{
  background-color: #282C2F;
  text-decoration: underline;
  color: white;
  font-weight: 600;
  width: fit-content;
  padding: 7px 10px;
  border-radius: 5px;
}

.bgcolordark{
  background-color: #282c34;
  color: white !important;
}
.bgcolorwhite{
  background-color: white;
} 

.footerbx{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px !important;
  height: 430px !important;
}

.swiper-slide img {
  display: block;
}

a{
  text-decoration: none !important;
}

.mobile-only {
    display: none; /* Hide by default */
}

/* Styles for desktop-only */
.desktop-only {
    display: block; /* Show by default */
}

/* Media query for mobile devices */
@media only screen and (max-width: 767px) {
    .mobile-only {
        display: block; /* Show on mobile */
    }

    .desktop-only {
        display: none; /* Hide on mobile */
    }

    .imagicarouselheighmobile{
      height: 34vh !important;
      width: 100% !important;
    }
}

/* Media query for desktop screens */
@media only screen and (min-width: 768px) {
    .mobile-only {
        display: none; /* Hide on desktop */
    }

    .desktop-only {
        display: block; /* Show on desktop */
    }
}